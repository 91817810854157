import {useMemo} from 'react';
import {useIntl} from 'gatsby-plugin-intl';

export const useSubjectConfig = () => {
    const {formatMessage} = useIntl();

    const estimatesConfig = [
        {title: formatMessage({id: 'contact-form.subject.estimates.app-development'})},
        {title: formatMessage({id: 'contact-form.subject.estimates.graphic-design'})},
        {title: formatMessage({id: 'contact-form.subject.estimates.illustration'})},
        {title: formatMessage({id: 'contact-form.subject.estimates.software-development'})},
        {title: formatMessage({id: 'contact-form.subject.estimates.web-design'})}
    ]

    const projectRequestConfig = [
        {title: formatMessage({id: 'contact-form.subject.project-request.general-project-request'})},
        {title: formatMessage({id: 'contact-form.subject.project-request.freelancer-wanted'})},
        {title: formatMessage({id: 'contact-form.subject.project-request.team-wanted'})},
        {title: formatMessage({id: 'contact-form.subject.project-request.1-3-months'})},
        {title: formatMessage({id: 'contact-form.subject.project-request.6-12-months'})}
    ]

    const subjectConfig = [
        {title: formatMessage({id: 'contact-form.subject.estimates'}), options: estimatesConfig},
        {title: formatMessage({id: 'contact-form.subject.application'})},
        {title: formatMessage({id: 'contact-form.subject.feedback'})},
        {title: formatMessage({id: 'contact-form.subject.become-a-partner'})},
        {title: formatMessage({id: 'contact-form.subject.project-request'}), options: projectRequestConfig},
        {title: formatMessage({id: 'contact-form.subject.other'})}
    ]

    return useMemo(() => subjectConfig, [subjectConfig]);
}
