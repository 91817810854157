import React, {useState, useRef, useMemo} from 'react';
import {Select} from 'antd';
import useOutsideListener from '../../hooks/useOutsideListener';
import ArrowDownIcon from '../icons/ArrowDownIcon';

const findOption = (value, config) => {
    return config.find(element => element.title === value);
};

const {Option} = Select;

const CascadeSelect = ({ config, placeholder, id, onChange, value}) => {
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [subSelectKey, setSubSelectKey] = useState(null);
    const containerRef = useRef(null);
    const popupContainerRef = useRef(null);

    const subSelectOptions = useMemo(() => {
        return subSelectKey && findOption(subSelectKey, config).options;
    }, [subSelectKey, config]);

    const currentOptions = useMemo(() => {
        return subSelectOptions ? [{title: subSelectKey, parent: true}, ...subSelectOptions] : config;
    }, [subSelectOptions, subSelectKey, config]);

    useOutsideListener([containerRef, popupContainerRef], () => {
        setSelectOpen(false);
    });

    return (
        <div ref={containerRef}>
            <div ref={popupContainerRef} />
            <Select
                listHeight={300}
                className="custom-cascade-select ant-customize-input"
                dropdownClassName="custom-cascade-select-dropdown"
                value={value?.split(', ').pop()}
                placeholder={placeholder}
                open={isSelectOpen}
                getPopupContainer={() => popupContainerRef.current}
                onClick={(event) => {
                    if (!(popupContainerRef.current && (popupContainerRef.current.contains(event.target) || popupContainerRef.current === event.target))) {
                        setSelectOpen(!isSelectOpen)
                    }
                }}
                onChange={(title, optionProps) => {
                    const value = subSelectKey && subSelectKey !== title ? `${subSelectKey}, ${title}` : title;
                    onChange(value, optionProps);
                }}
                onSelect={(value) => {
                    const selectedOption = findOption(value, currentOptions)
                    if (selectedOption?.options) {
                        setSubSelectKey(value);
                    } else if (selectedOption?.parent) {
                        setSubSelectKey(null);
                    } else {
                        setSelectOpen(!isSelectOpen);
                    }
                }}
                id={id}
                suffixIcon={ArrowDownIcon}
                >
                {currentOptions.map((subject) => {
                    const className = `custom-cascade-select-option ${subject.options ? 'with-options' : ''} ${subject.parent ? 'with-parent' : ''}`
                    return <Option className={className} key={subject.title} value={subject.title}>
                        {subject.parent && <ArrowDownIcon />}
                        <span>{subject.title}</span>
                        {subject.options && <ArrowDownIcon />}
                    </Option>
                })})
            </Select>
        </div>
    );
}

export default CascadeSelect;
