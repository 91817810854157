import React from 'react';
import {Typography} from 'antd';
import styles from '../components/short-contact/ShortContact.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import ContactForm from '../components/contact-form/ContactForm';
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import {graphql} from 'gatsby';

const Contact = ({location, data}) => {
    const {formatMessage} = useIntl();
    const {Title, Text} = Typography;

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'contact.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'contact.page-description'})} />
            </Helmet>
            <div className="container">
                <div className="section section--white component-visible">
                    <Title level={3} className={'custom-title'}>
                        <span className={'word animated'}>{formatMessage({id: 'contact-block.title.first-word'})}</span> {formatMessage({id: 'contact-block.title.rest'})}
                    </Title>
                    <Text className={styles.description}>{formatMessage({id: 'contact-page-block.description'})}</Text>
                    <ContactForm location={location} />
                </div>
            </div>
        </Layout>
    )
};

export default Contact;

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;