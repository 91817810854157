import React, {createRef, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, Radio, Row, Typography} from 'antd';
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl';
import styles from './ContactForm.module.less';
import {Link} from 'gatsby';
import {useCustomForm} from '../../hooks/useCustomForm';
import Recaptcha from '../recaptcha/Recaptcha';
import CascadeSelect from '../cascade-select/CascadeSelect'
import {useSubjectConfig} from '../../configs/contact-form-config'

const ContactForm = ({location}) => {
    const {TextArea} = Input;
    const {formatMessage, locale} = useIntl();
    const {Text} = Typography;
    const recaptchaRef = createRef();
    const [form] = Form.useForm();
    const {handleSendForm, isFormValid, handleValidation, sendStatus, getFieldError} = useCustomForm(form);

    const onFinish = () => {
        recaptchaRef.current.execute();
    };

    const handleCaptchaResolved = (response) => {
        handleSendForm(response);
    };

    const [emailValidateTrigger, setEmailValidateTrigger] = useState('onBlur');
    const validateTrigger = () => (getFieldError('email').length > 0 ? 'onChange' : 'onBlur');

    return (
        <Form
            form={form}
            name="contact-form"
            initialValues={{
                title: 1,
                subject: location && location.state && location.state.subject || null,
            }}
            className={styles.form}
            onFinish={onFinish}
            onFieldsChange={handleValidation}
        >
            <Form.Item name="title">
                <Radio.Group>
                    <Radio value={1}>{formatMessage({id: 'contact-form.radio.label.frau'})}</Radio>
                    <Radio value={2}>{formatMessage({id: 'contact-form.radio.label.herr'})}</Radio>
                </Radio.Group>
            </Form.Item>
            <Row gutter={{xs: 0, sm: 30}}>
                <Col xs={{span: 12}} sm={{span: 6}}>
                    <Form.Item
                        name="lastName"
                        rules={[{
                            required: true,
                            message: formatMessage({id: 'validation.last-name.required'}),
                            whitespace: true,
                        }]}
                    >
                        <Input placeholder={formatMessage({id: 'contact-form.last-name.placeholder'})} />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} sm={{span: 6}}>
                    <Form.Item
                        name="firstName"
                        rules={[{
                            required: true,
                            message: formatMessage({id: 'validation.first-name.required'}),
                            whitespace: true,
                        }]}
                    >
                        <Input placeholder={formatMessage({id: 'contact-form.name.placeholder'})} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={{xs: 0, sm: 30}}>
                <Col xs={{span: 12}} sm={{span: 6}}>
                    <Form.Item
                        name="email"
                        rules={[
                            {required: true, message: formatMessage({id: 'validation.email.required'})},
                            {
                                type: 'email',
                                message: formatMessage({id: 'validation.email.invalid'}),
                            },
                        ]}
                        validateTrigger={emailValidateTrigger}
                    >
                        <Input
                            placeholder={formatMessage({id: 'contact-form.email.placeholder'})}
                            onChange={() => setEmailValidateTrigger(validateTrigger())}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} sm={{span: 6}}>
                    <Form.Item
                        name="subject"
                    >
                        <CascadeSelect
                            config={useSubjectConfig()}
                            placeholder={formatMessage({id: 'contact-form.subject.placeholder'})}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                extra={
                    <FormattedMessage
                        id={'contact-form.captcha'}
                        values={{
                            a1: (chunks) => <a href={`https://www.google.com/intl/${locale}/policies/privacy/`}
                                               target='_blank' rel='noopener noreferrer'>{chunks}</a>,
                            a2: (chunks) => <a href={`https://www.google.com/intl/${locale}/policies/terms/`}
                                               target='_blank' rel='noopener noreferrer'>{chunks}</a>
                        }}
                    >
                        {(chunks) => <Text type={'secondary'} className="caption">{chunks}</Text>}
                    </FormattedMessage>
                }
                name="message"
                rules={[{
                    required: true,
                    message: formatMessage({id: 'validation.message.required'}),
                    whitespace: true,
                }]}
            >
                <TextArea
                    placeholder={formatMessage({id: 'contact-form.textarea.placeholder'})}
                    rows={5}
                    style={{resize: 'none'}}
                />
            </Form.Item>
            <Row type={'flex'} className={styles.checkboxRow}>
                <Col>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[{
                            validator: (_, value) => value ? Promise.resolve() : Promise.reject(formatMessage({id: 'validation.agreement.required'})),
                        }]}
                    >
                        <Checkbox>
                            <FormattedMessage
                                id={'contact-form.agreement'}
                                values={{
                                    strong: (chunks) => <strong><Link to={`/${locale}/legal`}>{chunks}</Link></strong>,
                                }}
                            >
                                {(chunks) => <span>{chunks}</span>}
                            </FormattedMessage>
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col className={styles.centerMobile}>
                    <Form.Item
                        validateStatus={sendStatus}
                        help={sendStatus !== '' && formatMessage({id: `contact-form.send.${sendStatus}`})}
                    >
                        <Button type="primary" htmlType="submit" disabled={!isFormValid} className="animated">
                            {formatMessage({id: 'contact-form.button.send'})}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Recaptcha
                recaptchaRef={recaptchaRef}
                onVerify={handleCaptchaResolved}
            />
        </Form>
    );
};

export default ContactForm;
